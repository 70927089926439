"use client";

import React, { FC, useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import TextButton from "@/components/Buttons/Text/Button";
import Logo from "@/public/logo.svg";
import { twMerge } from "tailwind-merge";
import { useSearchParams } from "next/navigation";
import { toast } from "sonner";
import useOutsideCheck from "@/hooks/useOutsideCheck";
import { useTranslations } from "next-intl";
const ParamsLink = dynamic(
  () => import("@/components/pages/Landings/ParamsLink"),
);
import Arrow from "public/icons/arrowDown.svg";
import LoginOptions from "@/components/pages/Login/LoginOptions";
import LoginWrapper from "@/components/pages/Login/LoginWrapper";
import LoginEmail from "@/components/pages/Login/LoginEmail";
import dynamic from "next/dynamic";

export enum LOGIN_VIEW {
  Options,
  Email,
}

const LoginPage: FC = () => {
  useOutsideCheck();
  const t = useTranslations();
  const { data: session } = useSession();
  const params = useSearchParams();
  const [openedView, openView] = useState(LOGIN_VIEW.Options);
  const [isErrorShown, errorShown] = useState(false);

  useEffect(() => {
    if (isErrorShown && params.get("error")) {
      toast.error(params.get("error"));
    }
    errorShown(true);
  }, [isErrorShown, params]);

  if (session && session.provider === "credentials") return null;

  return (
    <LoginWrapper
      noMobileGradient={openedView === LOGIN_VIEW.Email}
      noMobileLottie={openedView === LOGIN_VIEW.Email}
    >
      <div
        className={twMerge(
          "w-full flex justify-center md:max-w-[410px] md:p-[30px_30px_10px]",
          openedView === LOGIN_VIEW.Email && "justify-start p-[20px_20px]",
        )}
      >
        {openedView === LOGIN_VIEW.Email && (
          <TextButton
            beforeIcon={<Arrow className="rotate-90" />}
            onClick={() => openView(LOGIN_VIEW.Options)}
            label={t("Profile.return")}
            className="font-normal text-black-400 text-[14px]"
          />
        )}
        {openedView === LOGIN_VIEW.Options && (
          <ParamsLink
            className="hidden md:inline-block relative z-10 shrink-0"
            href="/"
          >
            <Logo />
          </ParamsLink>
        )}
      </div>
      <div className="relative z-10 grow w-full md:max-w-[410px] p-[0_20px_20px] md:p-[0_30px_30px] flex flex-col md:justify-center items-center">
        {openedView === LOGIN_VIEW.Email && <LoginEmail />}
        {openedView === LOGIN_VIEW.Options && (
          <LoginOptions openView={openView} />
        )}
        <p className="text-center text-[14px]">
          {t("Login.dontHaveAccount")}{" "}
          <ParamsLink
            className="text-primary font-bold hover:underline"
            href="/register"
          >
            {t("Login.signUp")}
          </ParamsLink>
        </p>
      </div>
    </LoginWrapper>
  );
};

export default LoginPage;
