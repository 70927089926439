import { useEffect } from "react";
import { useSession } from "next-auth/react";
import { usePathname } from "@/navigation";
import { useRouter } from "navigation";
import en from "@/messages/en/marketing.json";
import de from "@/messages/de/marketing.json";

const useOutsideCheck = () => {
  const { data: session } = useSession();
  const pathname = usePathname();
  const router = useRouter();
  const isOutsidePathname =
    pathname.includes("/login") ||
    pathname.includes("/register") ||
    pathname.includes("/password/edit") ||
    pathname.includes("/confirmation") ||
    pathname.includes(`/${en.Router.whyRewards.toLowerCase()}`) ||
    pathname.includes(`/${de.Router.whyRewards.toLowerCase()}`) ||
    pathname.includes(`/${en.Router.withCashback.toLowerCase()}`) ||
    pathname.includes(`/${de.Router.withCashback.toLowerCase()}`) ||
    pathname.includes(`/${en.Router.withGames.toLowerCase()}`) ||
    pathname.includes(`/${de.Router.withGames.toLowerCase()}`) ||
    pathname.includes(`/${en.Router.withSurveys.toLowerCase()}`) ||
    pathname.includes(`/${de.Router.withSurveys.toLowerCase()}`) ||
    pathname.includes(`/${en.Router.howDoesRewardsWork.toLowerCase()}`) ||
    pathname.includes(`/${de.Router.howDoesRewardsWork.toLowerCase()}`);

  useEffect(() => {
    if (session && session.provider === "credentials" && isOutsidePathname) {
      router.push("/");
    }
  }, [session, router]);
};

export default useOutsideCheck;
