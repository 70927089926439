"use client";

import React, { FC } from "react";
import dynamic from "next/dynamic";
const LottieComponent = dynamic(() => import("@/components/Lottie/Lottie"), {
  ssr: false,
});
import { twMerge } from "tailwind-merge";

type Props = {
  children: React.ReactNode;
  noMobileLottie?: boolean;
  noMobileGradient?: boolean;
};

const LoginWrapper: FC<Props> = ({
  children,
  noMobileLottie,
  noMobileGradient,
}) => {
  return (
    <div className="relative flex flex-col items-center min-h-[100vh]">
      <div
        className={twMerge(
          "absolute z-0 top-0 left-0 w-[100vw] h-[70vh] bg-[linear-gradient(180deg,_rgba(242,_121,_40,_0.20)_-4.81%,_rgba(242,_121,_40,_0.11)_-4.8%,_rgba(242,_121,_40,_0.00)_107.55%)]",
          noMobileGradient && "hidden md:block",
        )}
      />
      <div
        className={twMerge(
          "grid grid-rows-2 md:grid-rows-1 grid-cols-1 md:grid-cols-[3fr_4fr] max-h-screen md:max-h-none w-full items-stretch grow",
          noMobileLottie && "grid-rows-1",
        )}
      >
        <div className="overflow-y-scroll order-2 md:order-1 flex flex-col items-center max-h-screen">
          {children}
        </div>
        <div
          className={twMerge(
            "order-1 md:order-2 p-[24px_20px_0] md:p-[24px]",
            noMobileLottie && "hidden md:block",
          )}
        >
          <div
            className="hidden md:block rounded-[20px] h-full bg-none md:bg-[url('/loginBackground.svg')] [&_.lf-player-container]:h-full md:[&_.lf-player-container]:h-[calc(100vh-48px)]"
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center top",
              backgroundRepeat: "no-repeat",
            }}
          >
            <LottieComponent
              className="h-full"
              getAnimationData={() => import("public/lottie/login.json")}
              id="login"
            />
          </div>
          <div
            className="block md:hidden rounded-[20px] h-full bg-none md:bg-[url('/loginBackground.svg')] [&_.lf-player-container]:h-full md:[&_.lf-player-container]:h-[calc(100vh-48px)]"
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center top",
              backgroundRepeat: "no-repeat",
            }}
          >
            <LottieComponent
              className="h-full"
              getAnimationData={() => import("public/lottie/loginMobile.json")}
              id="loginMobile"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginWrapper;
