"use client";

import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useLocale, useTranslations } from "next-intl";
import { toast } from "sonner";
import SecondaryButton from "@/components/Buttons/Secondary/Button";
import Google from "@/public/icons/google.svg";
import { signIn, signOut, useSession } from "next-auth/react";
import Apple from "@/public/icons/appleWhite.svg";
import Email from "@/public/icons/email.svg";
import { getErrorMessage } from "@/utils";
import { useRouter } from "@/navigation";
import { LOGIN_VIEW } from "@/components/pages/Login/index";

type Props = { openView: Dispatch<SetStateAction<LOGIN_VIEW>> };

const LoginOptions: FC<Props> = ({ openView }) => {
  const t = useTranslations();
  const locale = useLocale();
  const { data: session } = useSession();
  const router = useRouter();
  const [isGoogleLoading, setGoogleLoading] = useState(false);
  const [isAppleLoading, setAppleLoading] = useState(false);

  useEffect(() => {
    if (session && session.provider === "google") {
      setGoogleLoading(true);

      signIn(
        "credentials",
        {
          callbackUrl: `${window.location.origin}/`,
          redirect: false,
        },
        {
          id_token: session.id_token,
          provider: "google_oauth2",
        },
      )
        // @ts-ignore
        .then(({ error }) => {
          if (error) {
            toast.error(t(getErrorMessage(JSON.parse(error)?.type)));
            signOut({ redirect: false, callbackUrl: undefined });
            setGoogleLoading(false);
          } else {
            window.dataLayer?.push({
              event: "login",
            });
            router.push("/");
          }
        });
    }

    if (session && session.provider === "apple") {
      setAppleLoading(true);

      signIn(
        "credentials",
        {
          callbackUrl: `${window.location.origin}/`,
          redirect: false,
        },
        {
          id_token: session.id_token,
          provider: "apple",
          user_identity: session.user_identity,
        },
      )
        // @ts-ignore
        .then(({ error }) => {
          if (error) {
            toast.error(t(getErrorMessage(JSON.parse(error)?.type)));
            signOut({ redirect: false, callbackUrl: undefined });
            setAppleLoading(false);
          } else {
            window.dataLayer?.push({
              event: "login",
            });
            router.push("/");
          }
        });
    }
  }, [router, session]);

  return (
    <>
      <p className="text-[32px] leading-[38px] font-bold mb-[12px] text-center">
        {t("Login.title")}
      </p>
      <p className="text-[14px] mb-[20px] text-center">{t("Login.subtitle")}</p>
      <SecondaryButton
        className="group bg-white border-[1px] h-[58px] p-[12px_16px] text-black border-black-200 shadow-[0px_4px_0px_0px_#E7E7E1] mb-[12px] w-full hover:!border-black-200"
        label={t("Auth.ContinueWithGoogle")}
        labelClassName="group-hover:text-primary transition"
        beforeIcon={<Google className="w-[32px]" />}
        isLoading={isGoogleLoading}
        onClick={() => {
          setGoogleLoading(true);
          signIn("google", {
            callbackUrl: `${window.location.origin}/${
              locale === "de" ? "de/" : ""
            }login`,
          });
        }}
      />
      <SecondaryButton
        className="group bg-white border-[1px] h-[58px] p-[12px_16px] text-black border-black-200 shadow-[0px_4px_0px_0px_#E7E7E1] mb-[12px] w-full hover:!border-black-200"
        labelClassName="group-hover:text-primary transition"
        label={t("Auth.ContinueWithApple")}
        beforeIcon={<Apple className="w-[32px]" />}
        isLoading={isAppleLoading}
        onClick={() => {
          setAppleLoading(true);
          signIn("apple", {
            callbackUrl: `${window.location.origin}/${
              locale === "de" ? "de/" : ""
            }login`,
          });
        }}
      />
      <SecondaryButton
        className="group bg-white border-[1px] h-[58px] p-[12px_16px] text-black border-black-200 shadow-[0px_4px_0px_0px_#E7E7E1] mb-[24px] w-full hover:!border-black-200"
        label={t("LoginPage.Email")}
        labelClassName="group-hover:text-primary transition"
        beforeIcon={<Email className="w-[32px]" />}
        onClick={() => openView(LOGIN_VIEW.Email)}
      />
    </>
  );
};

export default LoginOptions;
