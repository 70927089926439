import { twMerge } from "tailwind-merge";
import { FC, ReactNode } from "react";
import Spinner from "@/components/Spinner/Spinner";

type Props = {
  className?: string;
  labelClassName?: string;
  label: string;
  onClick?: () => void;
  isLoading?: boolean;
  beforeIcon?: ReactNode;
  isDisabled?: boolean;
  withShadow?: boolean;
  noEvents?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
};

const Button: FC<Props> = ({
  className = "",
  labelClassName = "",
  label,
  isLoading,
  beforeIcon,
  onClick,
  isDisabled = false,
  withShadow = false,
  noEvents = false,
  type,
}) => {
  return (
    <button
      type={type}
      title={label}
      disabled={isDisabled || isLoading}
      className={twMerge(
        "relative group transition-all flex items-center border-[1px] border-primary hover:!border-primary-400 justify-center bg-transparent py-[16px] px-[32px] gap-[10px] text-[16px] rounded-[12px] font-bold outline-none",
        withShadow && "shadow-[0px_4px_0px_0px_#F27928]",
        isDisabled &&
          "border-black-200 text-black-400 hover:!border-black-200 cursor-not-allowed",
        isLoading && "cursor-not-allowed",
        noEvents && "pointer-events-none",
        className,
      )}
      onClick={onClick}
    >
      {!isLoading && beforeIcon}
      <span
        className={twMerge(
          "truncate",
          labelClassName,
          isLoading && "invisible",
        )}
      >
        {label}
      </span>
      {isLoading && (
        <Spinner
          className="absolute scale-[0.4] -translate-y-[7px]"
          innerClassName="text-black"
        />
      )}
    </button>
  );
};

export default Button;
