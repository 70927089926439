"use client";

import React, { FC, useState } from "react";
import Button from "@/components/Buttons/Primary/Button";
import { useTranslations } from "next-intl";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { EMAIL_REGEX } from "@/consts";
import TextInput from "@/components/Inputs/TextInput/TextInput";
import Email from "@/public/icons/email.svg";
import ErrorMessage from "@/components/ErrorMessage/ErrorMessage";
import LockIcon from "@/public/icons/lock.svg";
import { signIn } from "next-auth/react";
import { toast } from "sonner";
import { Link, useRouter } from "@/navigation";
import { getErrorMessage } from "@/utils";

type Props = {};
type FormValues = {
  email: string;
  password: string;
};

const LoginEmail: FC<Props> = () => {
  const t = useTranslations();
  const router = useRouter();
  const [isLoading, setLoading] = useState(false);

  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const onLogin: SubmitHandler<FormValues> = (data) => {
    setLoading(true);

    signIn("credentials", {
      email: data.email,
      password: data.password,
      callbackUrl: `${window.location.origin}/`,
      redirect: false,
    })
      // @ts-ignore
      .then(({ error }) => {
        if (error) {
          toast.error(t(getErrorMessage(JSON.parse(error)?.type)));
          setLoading(false);
        } else {
          window.dataLayer?.push({
            event: "login",
          });
          router.push("/");
        }
      });
  };

  return (
    <>
      <p className="text-[22px] md:text-[32px] leading-[30px] md:leading-[38px] font-bold mb-[12px] w-full md:text-center">
        {t("Login.title")}
      </p>
      <Controller
        control={control}
        name="email"
        rules={{
          required: {
            value: true,
            message: t("Login.emailError"),
          },
          pattern: {
            value: EMAIL_REGEX,
            message: t("Login.emailInvalid"),
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextInput
              placeholder={t("Login.emailPlaceholder")}
              label={t("Login.email")}
              value={value}
              id="login-email-input"
              onChange={onChange}
              className="mb-[12px]"
              error={error?.message}
              icon={<Email className="text-black-400 w-[24px]" />}
            />
            {error?.message && (
              <ErrorMessage
                error={error.message}
                className="mb-[10px] text-left w-full"
              />
            )}
          </>
        )}
      />
      <Controller
        control={control}
        name="password"
        rules={{
          required: {
            value: true,
            message: t("Login.passwordError"),
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextInput
              placeholder={t("Login.passwordPlaceholder")}
              value={value}
              label={t("Login.password")}
              onChange={onChange}
              type="password"
              icon={<LockIcon className="w-[24px] text-black-400" />}
              className="mb-[12px]"
              error={error?.message}
            />
            <div className="mb-[24px] flex items-start justify-between w-full gap-[20px]">
              <div>
                {error?.message && (
                  <ErrorMessage
                    error={error.message}
                    className="mb-[12px] text-left w-full"
                  />
                )}
              </div>
              <Link
                href="/password"
                className="text-primary font-bold hover:underline text-[14px] ml-auto cursor-pointer"
              >
                {t("Login.forgot")}
              </Link>
            </div>
          </>
        )}
      />
      <Button
        label={t("Login.title")}
        className="w-full mb-[24px]"
        isLoading={isLoading}
        onClick={handleSubmit(onLogin)}
      />
    </>
  );
};

export default LoginEmail;
